import { ChatProvider, RoqProvider } from 'gf-roq-ui-react';
import React, { FC, ReactNode } from 'react';

import { ROQ_HOST } from '../../config';

import { useIsChatEnabled } from './useIsChatEnabled';

import { useRoqLocale } from './useRoqLocale';
import { useRoqToken } from './useRoqToken';

type Props = {
  children: ReactNode;
};

/**
 * Main application entry point for authenticated users, wrapped
 * with the appropriate provides (ROQ, Matomo)
 *
 * @param props          Props passed to the component
 * @param props.children Authenticated app
 * @returns              RoqChatProvider component
 */
const RoqChatProvider: FC<Props> = ({ children }) => {
  const [token, setIsTokenExpired] = useRoqToken();
  const locale = useRoqLocale();
  const isEnabled = useIsChatEnabled();

  if (isEnabled !== true) {
    return <>{children}</>;
  }

  return (
    <RoqProvider
      config={{
        host: ROQ_HOST,
        socket: true,
        token,
      }}
      locale={locale}
      onTokenExpired={() => {
        setIsTokenExpired(true);
      }}
    >
      <ChatProvider enableGroups={false}>{children}</ChatProvider>
    </RoqProvider>
  );
};

export default RoqChatProvider;
