/**
 * @file contains functions used to create urls to reroute via <Link /> or navigate
 * Can be same with the path routes, but we keep urls to be future-proof
 */

import { ChatConversationInterface } from 'gf-roq-ui-react';

import { ROUTE__CHAT } from '../../constants/routing/routes';
import { Hotel } from '../../generated/graphql';
import { reportError } from '../../services/reporting';

import { getNoMatchUrl } from './404';
import { getHomeUrl } from './home';

/**
 * Get URL for chat route
 *
 * @param hotelId ID of the hotel
 * @returns       A string in a url format
 */
export const getChatUrl = (hotelId: Hotel['id'] | null): string => {
  if (hotelId === null) {
    reportError('HotelId passed to getHomeUrl is null');
    return getNoMatchUrl();
  }
  return `/${hotelId}/${ROUTE__CHAT}`;
};

/**
 * Get URL for chat conversation route
 *
 * @param hotelId        ID of the hotel
 * @param conversationId ID of the conversation
 * @returns              A string in a url format
 */
export const getChatConversationUrl = (
  hotelId: Hotel['id'] | null,
  conversationId: ChatConversationInterface['id'] | null,
) => {
  if (hotelId === null) {
    reportError('HotelId passed to getChatConversationUrl was null');
    return getNoMatchUrl();
  }

  if (conversationId === null) {
    reportError('ConversationId passed to getChatConversationUrl was null');
    return getHomeUrl(hotelId);
  }

  return `/${hotelId}/${ROUTE__CHAT}/${conversationId}`;
};
