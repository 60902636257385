/**
 * @file contains functions used to create urls to reroute via <Link /> or navigate
 * Can be same with the path routes, but we keep urls to be future-proof
 */
import { Location } from 'react-router-dom';

import {
  ROUTE__FEED,
  ROUTE__KANBAN,
  ROUTE__REPLIES,
} from '../../constants/routing/routes';
import { Hotel, Message, Topic } from '../../generated/graphql';
import SearchParams from '../../models/searchParams';
import { reportError } from '../../services/reporting';

import { getIsFeedKanbanPage } from '../helpers/pages';
import {
  removeParamsKeysFromUrl,
  replaceOrAddParamsToUrl,
} from '../helpers/router';

import { getNoMatchUrl } from './404';
import { getHomeUrl } from './home';
import { getTopicUrl } from './topic';

/**
 * Gets url that opens returns message details on the feed
 *
 * @param hotelId   ID of the hotel
 * @param location  Location object from the history or null if we don't want search params to be kept
 * @param messageId ID of the message
 * @param topicId   ID of the topic
 * @returns         A string in a url format
 */
export const getMessageDetailsUrl = (
  hotelId: Hotel['id'] | null,
  location: Location | null,
  messageId: Message['id'] | null,
  topicId: Topic['id'] | null,
): string => {
  if (hotelId === null) {
    reportError('HotelId passed to getMessageDetailsUrl was null');
    return getNoMatchUrl();
  }

  if (topicId === null) {
    reportError('TopicId passed to getMessageDetailsUrl was null');
    return getHomeUrl(hotelId);
  }

  if (messageId === null) {
    reportError('MessageId passed to getMessageDetailsUrl was null');
    return getTopicUrl(hotelId, location, topicId);
  }

  const isKanbanPage = getIsFeedKanbanPage();

  const ROUTE = isKanbanPage ? ROUTE__KANBAN : ROUTE__FEED;

  const search = location?.search ?? '';
  return `/${hotelId}/${ROUTE}/${topicId}/post/${messageId}/details${search}`;
};

/**
 * Gets URL for message details with replies open
 *
 * @param hotelId   ID of the hotel
 * @param location  Location object from the history or null if we don't want search params to be kept
 * @param messageId ID of the message
 * @param topicId   ID of the topic
 * @returns         A string in a URL format
 */
export const getMessageDetailsRepliesUrl = (
  hotelId: Hotel['id'] | null,
  location: Location | null,
  messageId: Message['id'] | null,
  topicId: Topic['id'] | null,
) => {
  const params: SearchParams = new URLSearchParams(location?.search ?? '');

  if (hotelId === null) {
    reportError('HotelId passed to getMessageDetailsUrl was null');
    return getNoMatchUrl();
  }

  if (topicId === null) {
    reportError('TopicId passed to getMessageDetailsUrl was null');
    return getHomeUrl(hotelId);
  }

  if (messageId === null) {
    reportError('MessageId passed to getMessageDetailsUrl was null');
    return getTopicUrl(hotelId, location, topicId);
  }

  params.set(ROUTE__REPLIES, messageId);

  const isKanbanPage = getIsFeedKanbanPage();

  const ROUTE = isKanbanPage ? ROUTE__KANBAN : ROUTE__FEED;

  return `/${hotelId}/${ROUTE}/${topicId}/post/${messageId}/details?${params.toString()}`;
};

/**
 * Gets url that adds `?replies={messageId}` search param
 *
 * @param location  Location object from the history
 * @param messageId ID of the message
 * @returns         A string in a url format
 */
export const getOpenRepliesUrl = (
  location: Location,
  messageId: Message['id'],
): string => {
  return replaceOrAddParamsToUrl(location, { replies: messageId });
};
/**
 * Gets url that removes `?replies={messageId}` search param
 *
 * @param location Location object from the history
 * @returns        A string in a url format
 */
export const getCloseRepliesUrl = (location: Location): string => {
  return removeParamsKeysFromUrl(location, ROUTE__REPLIES);
};
