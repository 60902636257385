/**
 * @file Hook for scrolling into element from hash tag in referenced DOM element
 */

import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * When the data loads, scroll into the element referenced by the url hash
 *
 * @param hasLoaded Whether data was loaded (nothing to scroll into before that)
 */
const useDetailsScroll = (hasLoaded: boolean) => {
  const { hash } = useLocation();

  useLayoutEffect(() => {
    if (hash === '' || hasLoaded === false) {
      return;
    }

    const element = document.querySelector(hash);
    element?.scrollIntoView({
      behavior: 'smooth',
    });
  }, [hash, hasLoaded]);
};

export default useDetailsScroll;
