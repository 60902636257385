import React, { FC } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import useMessageRead from '../../components/Message/hooks/useMessageRead';
import useMessageSectionUrl from '../../components/Message/hooks/useMessageSectionUrl';
import useParamMessageEditMode from '../../hooks/router/params/useParamMessageEditMode';
import useParamMessageId from '../../hooks/router/params/useParamMessageId';
import useParamTopicId from '../../hooks/router/params/useParamTopicId';
import useIsMobile from '../../hooks/useIsMobile';
import { MessageEditModeParam } from '../../models/pageParams';
import AppState from '../../models/state';
import { getLabelMessageDetailsCloseUrl } from '../../routes/urls/label';

import { useMessageUnArchiveCheck } from '../modals/MessageUnArchive/useMessageUnArchiveCheck';

import MessageAssignment from './assignment';
import MessageDateDue from './dateDue';
import MessageLabels from './labels';
import MessageOverview from './overview';
import { Props, PropsInner, StateProps } from './props';
import MessageText from './text';

/**
 * Full message details with editing for all fields
 * (in case the user has permissions to do it)
 *
 * @param props                       Props passed to the container
 * @param props.networkMessageDetails Whether the debug option for fetching this is on
 * @returns                           The container itself
 */
const MessageFull: FC<Props> = ({ networkMessageDetails }) => {
  const location = useLocation();
  const topicId = useParamTopicId();
  const messageId = useParamMessageId();
  const linkToDetails = useMessageSectionUrl({ mode: null });
  const messageEditMode = useParamMessageEditMode();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  useMessageRead();

  useMessageUnArchiveCheck(messageId);

  /**
   * Request that the message returns to overview mode
   */
  const requestModeOverview = () => {
    /**
     * With labels, we use deep links, so we need to make sure that all params
     * are removed when closing
     */
    const url =
      messageEditMode === 'label'
        ? getLabelMessageDetailsCloseUrl(location, linkToDetails)
        : linkToDetails;

    navigate(url, { replace: true });
  };

  // Mapping between all the modes the container can be in
  // And the components that are responsible for rendering that mode
  // Title and description have special screens on mobile, while on desktop they do not
  const mapping = new Map<MessageEditModeParam | null, FC<PropsInner>>([
    [null, MessageOverview],
    ['assign', MessageAssignment],
    ['date', MessageDateDue],
    ['description', isMobile ? MessageText : MessageOverview],
    ['label', MessageLabels],
    ['title', isMobile ? MessageText : MessageOverview],
  ]);

  const Component = mapping.get(messageEditMode);

  if (messageId === null || topicId === null || Component === undefined) {
    return null;
  }

  return (
    <Component
      messageId={messageId}
      networkMessageDetails={networkMessageDetails}
      requestModeOverview={requestModeOverview}
      topicId={topicId}
    />
  );
};

/**
 * Map part of Redux state to container props
 *
 * @param state The current state
 * @returns     Props to add to the container
 */
const mapStateToProps: MapStateToProps<
  StateProps,
  Record<string, never>,
  AppState
> = state => ({
  networkMessageDetails: state.debug.networkMessageDetails,
});

export default connect(mapStateToProps)(MessageFull);
