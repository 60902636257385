/**
 * @file contains helper functions for manipulating url search params
 */

import { Location } from 'react-router-dom';

import SearchParams, { SearchParamsKey } from '../../models/searchParams';

/**
 * Replaces or adds params to the current URL based on the location
 *
 * @param location  location object from the history
 * @param paramsObj an object containing key-value pairs of search parameters to replace/add
 * @returns         a string in a URL format
 */
export const replaceOrAddParamsToUrl = (
  location: Location,
  paramsObj: Partial<Record<SearchParamsKey, string[] | string>>,
): string => {
  const params: SearchParams = new URLSearchParams(location.search);

  Object.entries(paramsObj).forEach(([key, values]) => {
    const currentParam = params.get(key);

    const valuesArray = Array.isArray(values) ? values : [values];

    if (currentParam === null) {
      valuesArray?.forEach(value => params.append(key, value));
    } else {
      params.set(key, encodeURIComponent(String(valuesArray)));
    }
  });

  return `${location.pathname}?${params.toString()}`;
};

/**
 * Removes params from the current URL based on the location
 *
 * @param location  location object from the history
 * @param paramsObj an object containing key-value pairs of search parameters to remove
 * @returns         a string in a URL format
 */
export const removeParamsFromUrl = (
  location: Location,
  paramsObj: Partial<Record<SearchParamsKey, string[] | string>>,
): string => {
  const params: SearchParams = new URLSearchParams(location.search);

  Object.entries(paramsObj).forEach(([key, values]) => {
    const currentParams = params.get(key);

    const valuesArr = Array.isArray(values) ? values : [values];

    if (currentParams) {
      const newParams = currentParams
        .split(',')
        .filter(param => !valuesArr.includes(param as SearchParamsKey))
        .join(',');

      if (newParams) {
        params.set(key, newParams);
      } else {
        params.delete(key);
      }
    }
  });

  return `${location.pathname}?${params.toString()}`;
};

/**
 * Removes params from the current URL based on the location
 *
 * @param location location object from the history
 * @param keys     param keys that we want to remove
 * @returns        a string in a URL format
 */
export const removeParamsKeysFromUrl = (
  location: Location,
  ...keys: SearchParamsKey[]
): string => {
  const { hash, pathname, search } = location;
  const params: SearchParams = new URLSearchParams(search);

  keys.forEach(key => {
    params.delete(key);
  });

  if (Array.from(params).length === 0) {
    return `${pathname}${hash}`;
  }

  return `${pathname}?${params.toString()}${hash}`;
};

/**
 * Parses URL string
 *
 * @param routerUrl a partial url string (React Router type, without http)
 * @returns         a tuple of pathname and search string
 */
export const parseUrlString = (routerUrl: string): [string, string] => {
  const [pathname, searchString] = routerUrl.split('?');

  const search = searchString ? `?${searchString}` : '';
  return [pathname, search];
};

type Configuration = {
  params?: Partial<Record<SearchParamsKey, string | string[]>>;
  paramsToRemove?: SearchParamsKey[];
};

/**
 * Modifies the URL based on the location by adding/replace-ing existing params and removing params
 *
 * @param location               History location object
 * @param options                Object containing params to be set, replaced or removed
 * @param options.params         Params to be set or replaced
 * @param options.paramsToRemove Param keys we want to remove from the url
 * @returns                      a string in a URL format
 */
export const modifyUrlParams = (location: Location, options: Configuration) => {
  const { search, pathname } = location;
  const params: SearchParams = new URLSearchParams(search);

  const { params: paramsObj, paramsToRemove } = options ?? {};

  if (paramsObj) {
    Object.entries(paramsObj).forEach(([key, values]) => {
      const currentParam = params.get(key);

      const valuesArray = Array.isArray(values) ? values : [values];

      if (currentParam === null) {
        params.append(key, valuesArray.join(','));
      } else {
        params.set(key, valuesArray.join(','));
      }
    });
  }

  if (paramsToRemove) {
    paramsToRemove.forEach(key => {
      params.delete(key);
    });
  }

  return `${pathname}?${params.toString()}`;
};

/**
 * Checks wether provided params exist and match in the URL based on the location
 *
 * @param location  History location object
 * @param paramsObj Object containing search param key value pairs
 * @returns         Wether the params match
 */
export const hasMatchingParams = (
  location: Location,
  paramsObj: Partial<Record<SearchParamsKey, string | string[]>>,
) => {
  const { search } = location;
  const params: URLSearchParams = new URLSearchParams(search);

  return Object.entries(paramsObj).every(([param, providedP]) => {
    const p = params.get(param);
    return p !== null && providedP !== undefined && p === providedP.toString();
  });
};
